/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-08-19 18:05:20
 * @LastEditors: your Name
 * @LastEditTime: 2024-05-30 16:51:21
 */
import proxy from '../proxy';

const URL = proxy + '/webapi';
export const baseURL = proxy;
export const baseURLAuth = proxy + '/auth';
export default URL;
