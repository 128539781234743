/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-08-19 18:04:48
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-08 16:26:27
 */
import api from './index'
import axios from '../axios'
import { webAPIRequest, webAPIResponse } from './index.globals'


const MODULE = '/api/company'

/** 组织树 */
export const getCompanyList = (data: webAPIRequest.CompanyTree) => axios.post<webAPIResponse.CompanyTree[]>(`${api}${MODULE}/getCompanyList`, data)

/** 终端-安装厂商操作员企业树 */ 
export const getCompanyListNoAuthByType = (payload: webAPIRequest.CompanyTree) => axios.post<webAPIResponse.CompanyTree[]>(`${api}${MODULE}/getCompanyListNoAuthByType`, payload)

/** 企业车队树 */
export const getCompanyDepartmentTree = (data: any) => axios.post<any[]>(`${api}${MODULE}/companyDepartmentTree`, data)

/** 企业车队数组 */
export const getCompanyDepartmentList = (data: any) => axios.post<any[]>(`${api}${MODULE}/getDepCompanyList`, data)

/** 去除用户上次选择企业 */
export const getCheckedCompany = () => axios.post<Array<string>>(`${api}${MODULE}/getRedisCompanyIds`, {})