import React from 'react';
import {Spin} from "antd";

const VerticalCenterSpin = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%'
    }}>
      <Spin size={'large'}/>
    </div>
  );
};

export default VerticalCenterSpin;
