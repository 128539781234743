import React, {Dispatch, SetStateAction} from 'react'
import {Modal, Table} from 'components'
import {headerFaultListService} from "server/web/device";
import {Button} from "antd";
import {ColumnsType} from "components/table/interface"
import {faultListStore, tableStore} from "store"

interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const FaultList: React.FC<Props> = ({visible, setVisible}) => {
  const tableName = 'header.faultList'

  const columns: ColumnsType<any> = [
    {
      dataIndex: 'deviceCode',
      intlTitle: 'tx1805091',
      description: '设备编号',
      width: 160,
      fixed: 'left'
    },
    {
      dataIndex: 'companyName',
      title: '所属企业',
      width: 180
    },
    {
      dataIndex: 'faultType',
      title: '故障类型',
      width: 180
    },
    {
      dataIndex: 'statusName',
      title: '处理状态',
      width: 180
    },
    {
      dataIndex: 'createTime',
      title: '验收时间',
      width: 180
    },
    {
      dataIndex: 'Action',
      intlTitle: 'tx000007',
      description: '操作',
      width: 200,
      fixed: 'right',
      render: (_text, record) => {
        if (record.status===0) {
          return (
            <Button
              type="link"
              size="small"
              onClick={async () => {
                await headerFaultListService.operate({id: record.id, status: '1'})
                tableStore.queryTable(tableName)
                await faultListStore.updateCount()
              }}
            >处理</Button>
          )
        }
        return null
      }
    }
  ]

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Modal
      title={'验收故障列表'}
      visible={visible}
      onOk={handleCancel}
      onCancel={handleCancel}
      width={960}
    >
      <div style={{height: 550}}>
        <Table
          tableName={tableName}
          columns={columns}
          rowKey="id"
          axios={{query: headerFaultListService.getByPage}}
        />
      </div>
    </Modal>
  );
};

export default FaultList;