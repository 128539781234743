import React from 'react'
import {isEqual} from "lodash-es";

interface AlarmTotalProps {
  title: string
  icon?: string
  alarmNum?: number
  value?: number | string
  // 数值是否显示百分比符号
  showPercent?: boolean
  // 用在做内容变更的时候闪烁动画的
  flag?: boolean
}

function NumberCard(props: AlarmTotalProps) {
  const {title, value, flag, showPercent = false, icon} = props

  return (
    <div className='tx-large-alarm-total'>
      <div className='large-alarm-total-content'>
        <div className='arge-alarm-total-data'>
          <div className={'large-content-total content-total-item'}>
            {
              props.icon ? (
                <div className='icon'>
                  <img style={{width: 50}} src={'/images/large_screen/' + icon + '.png'} alt=''/>
                </div>
              ) : null
            }
            <div className='content'>
              <div className={flag ? 'content-value fade1' : 'content-value fade2'}>
                {value || 0}
                {
                  showPercent ? <span style={{fontSize: 16}}>%</span> : null
                }
              </div>
              <div className='content-title'>
                {title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(NumberCard, isEqual)
