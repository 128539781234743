/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-09-17 11:09:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-20 14:38:10
 */
type ProxyType = '/api' | '/test'

/** 开发环境 */
// let proxy: ProxyType = '/api'

/** 测试环境 */
let proxy: ProxyType = '/test'

if (process.env.NODE_ENV !== 'development') {
  proxy = '/api'
}

let BASEURL = ''

export { BASEURL }
export default proxy
