/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-10-14 17:23:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 14:26:54
 */
import { action, observable, toJS } from "mobx";
import { webAPIResponse } from "server/web/index.globals";
import {
  getDataInspectionList,
  getDataInspectionPage,
} from "server/web/inspection";
import { getAttachment } from "server/web/alarm";
import { towgs84 } from "components/map/util/convert";
import { getWaybillByIds } from "server/web/concrete";
import monitorStore from "./index";
import { getMapAreaAll, getMapAreaAllCache } from "server/web/mapArea";
import { cloneDeep, includes, without } from "lodash-es";
import userSettingStore from "store/userSetting";

import { setLocal } from "util/comm";
import { Position as olPosition } from "components/ol/interface";
import moment from "moment";
import async from "router/async";

interface LngLat {
  lng: number;
  lat: number;
}

interface ActionsProps {
  type: string;
  tips: Array<number>;
  key: number;
}

class Position {
  // 巡检点位信息
  @observable pointInfos: any[] = [];

  /** 设置所有地图中心 */
  // 114.057939,22.543527
  // @observable mapAllCenter?: LngLat | olPosition = { lng: 114.057939, lat: 22.543527 }
  @observable mapAllCenter?: olPosition = [114.057939, 22.543527];
  /** 设置所有地图中心 */
  @observable mapAllType: string = "amap";
  /** 设置所有地图等级 */
  @observable mapAllZoom: number = 0;
  /** 地图类型 */
  @observable mapType: string = "amap";
  /** 设置地图中心 */
  @observable mapCenter?: LngLat = undefined;
  /** 地图缩放等级 */
  @observable mapZoom: number = 0;

  // 视图加载
  @observable drawLoading: boolean = false;

  /** infoWindowVisible */
  @observable infoWindowVisible: boolean = true;

  /** 巡检点位信息详情 - 用于infoWindow */
  @observable pointInfoWindow: any | null = null;

  /** 采集列表参数 */
  @observable PointsParams: {} = {};

  /** 更新infowindow显示隐藏 */
  @action
  updateInfoWindowVisible(status: boolean) {
    this.infoWindowVisible = status;
  }

  /** 更新视图加载 */
  @action
  updateDrawLoading(status: boolean) {
    this.drawLoading = status;
  }

  /** 更新地图中心点位 */
  @action
  updateMapCenter(data?: webAPIResponse.vehiclesInfo) {
    this.mapCenter =
      data && data.longitude
        ? {
            lng: data.longitude,
            lat: data.latitude,
          }
        : undefined;
  }

  /** 更新所有地图中心点位 */
  @action
  updateMapCenterValue(lng: number, lat: number) {
    this.mapCenter = { lng, lat };
  }

  /** 更新地图等级 */
  @action
  updateMapZoom(zoom: number) {
    // console.log(zoom)
    this.mapZoom = zoom;
  }

  /** 更新所有地图中心点位 */
  @action
  updateAllMapCenterValue(lng: number, lat: number) {
    this.mapAllCenter = [lng, lat];
    setLocal("mapCenter", [lng, lat]);
  }

  /** 更新地图等级 */
  @action
  updateAllMapZoom(zoom: number) {
    this.mapAllZoom = zoom;
    setLocal("mapZoom", zoom);
  }

  // 更新巡检点位信息infoWindow
  @action
  updatedPointInfoWindow(val: any) {
    this.pointInfoWindow = val;
    this.updateInfoWindowVisible(true);
  }

  /** 更新请求参数 */
  @action
  updatelPointsParmas(data: {}) {
    this.PointsParams = data;
  }

  //查询所有巡检点位信息
  @action
  async queryAllPoints(param: any) {
    if (param) {
      try {
        this.updateDrawLoading(true);
        let res: any = await getDataInspectionList(param);
        this.updateDrawLoading(false);
        let dataList = res?.data ?? [];
        dataList.forEach((tt: any) => {
          tt.latitude = +tt?.latitude;
          tt.longitude = +tt?.longitude;
        });
        this.pointInfos = dataList;
        if (dataList && dataList.length) {
          this.pointInfoWindow = dataList?.[0];
          this.infoWindowVisible = true;
        }
      } catch (error) {
        this.updateDrawLoading(false);
        this.pointInfoWindow = null;
        this.pointInfos = [];
        this.infoWindowVisible = false;
      }
    } else {
      this.pointInfoWindow = null;
      this.pointInfos = [];
      this.infoWindowVisible = false;
    }
  }

  //点击列表更新巡检点位信息infoWindow
  @action
  async listClickUpdateInfoWindo(filterId: number) {
    if (filterId) {
      let nList = this.pointInfos.filter((tt) => !!tt && tt.id === filterId);
      if (nList && nList.length) {
        this.pointInfoWindow = nList[0];
        this.updateInfoWindowVisible(true);

        if (nList[0]) {
          //更新中心点
          const position = [nList[0]?.longitude, nList[0]?.latitude];
          if (position && position.length) {
            this.updateAllMapCenterValue(
              position[0] ? +position[0] : 114.057939,
              position[1] ? +position[1] : 22.543527
            );
          }
        }
      } else {
        this.pointInfoWindow = null;

        this.infoWindowVisible = false;
      }
    } else {
      this.pointInfoWindow = null;

      this.infoWindowVisible = false;
    }
  }
}

const positionStory: Position = new Position();

export { Position };
export default positionStory;
