/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-10-15 17:40:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-07 15:03:03
 */
import api from '.';
import axios from '../axios';
import { TableResponseType } from 'components/table/interface';
import { bucAPIRequest, bucAPIResponse, Id, Ids } from './index.globals'

const MODULE = '/rights'

export interface QueryMenuAll extends Omit<bucAPIRequest.MenuList, 'current' | 'size'> { }

/** 分页查询菜单信息 */
export const queryMenuList = (payload: bucAPIRequest.MenuList) => axios.post<TableResponseType<bucAPIResponse.MenuList>>(`${api}${MODULE}/getRightsPage`, payload)

/** 查询所有菜单信息 */
export const queryMenuAll = (payload: QueryMenuAll) => axios.post<bucAPIResponse.MenuList[]>(`${api}${MODULE}/find/all`, payload)

/** 通过ID得到菜单信息 */
export const queryMenuInfo = (payload: Id) => axios.post<bucAPIResponse.MenuList>(`${api}${MODULE}/find/one`, payload)

/** 新增菜单 */
export const addMenu = (payload: bucAPIRequest.AddMenu) => axios.post<boolean>(`${api}${MODULE}/add`, payload)

/** 修改菜单信息 */
export const updateMenu = (payload: bucAPIRequest.UpdateMenu) => axios.post<boolean>(`${api}${MODULE}/update`, payload)

/** 根据ID删除菜单信息 */
export const deleteMenu = (payload: Ids) => axios.post<boolean>(`${api}${MODULE}/delete`, payload)



/** 根据id批量修改为停用 */
export const menuModifyDisable = (payload: Ids) => axios.post<boolean>(`${api}${MODULE}/modify/disable`, payload)

/** 根据id批量修改为启用 */
export const menuModifyNormal = (payload: Ids) => axios.post<boolean>(`${api}${MODULE}/modify/enabled`, payload)

/** 查询用户权限信息 */
export const getUserMenu = (payload: bucAPIRequest.GetUserMenu) => axios.post<bucAPIResponse.MenuList[]>(`${api}${MODULE}/getRightsByUser`, payload)
export const getRightsByCorrelType = (payload: any) => axios.post<any>(`${api}${MODULE}/getRightsByCorrelType`, payload)
/** 菜单查询-导出 */
export const exportRightsAll = (payload: any) => axios.post<boolean>(`${api}${MODULE}/exportRightsAll`, payload)