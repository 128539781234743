/**
 * 这个文件里的接口由于都是终端管理模块下面的，属于不同页面用于CURD，
 * 从属关系比较明确，所以换个组织方式，可能看起来会更清晰一点
 */

import { message } from 'antd'
import React from 'react'
import axios from '../axios'
import api from './index'

interface Id {
  id: React.Key
}

interface Ids {
  ids: React.Key[]
}

// 设备版本管理
export const deviceVersionService = {
  // 分页查询设备版本
  getByPage: (data: any) => axios.post(`${api}/deviceVersion/getPageBy`, data),
  // 查看设备版本详情
  getById: (data: any) => axios.post(`${api}/deviceVersion/getById`, data),
  // 添加设备版本
  add: (data: any) => axios.post(`${api}/deviceVersion/add`, data),
  // 修改设备版本
  update: (data: any) => axios.post(`${api}/deviceVersion/update`, data),
  // 删除设备版本
  remove: (data: any) => axios.post(`${api}/deviceVersion/deleteById`, data)
}

// 设备升级查询
export const deviceUpgradeService = {
  // 分页查询设备升级状况
  getByPage: (data: any) => axios.post(`${api}/deviceUpgrade/getPageBy`, data),
  // 批量作废设备升级情况
  removeByIds: (data: Ids) => axios.post(`${api}/deviceUpgrade/deleteById`, data),
  // 导出升级查询
  exportDeviceUpgrade: (data: any) => axios.post<any>(`${api}/deviceUpgrade//exportUpgradeInfo`, data)
}

// 设备指令查询
export const commandService = {
  // 分页查询设备指令状况
  getByPage: (data: any) => axios.post(`${api}/deviceCommand/getPageBy`, data),
  // 通过ID作废设备指令状况
  remove: (data: Id) => axios.post(`${api}/deviceCommand/deleteById`, data),
  // 批量作废
  removeBatch: (data: Ids) => axios.post(`${api}/deviceCommand/deleteBatchById`, data),
  // 导出指令查询
  exportCommandService: (data: any) => axios.post<any>(`${api}/deviceCommand/exportCommandInfo`, data)
}

// 最新自检
export const latestSelfCheckService = {
  // 分页查询最新自检状况
  getByPage: (data: any) => axios.post(`${api}/deviceAutodeteNew/getPageBy`, data),
  // 查看最新自检详情
  getById: (data: Id) => axios.post(`${api}/deviceAutodeteNew/getInfoBy`, data),
  // 查看最新自检的上一条/下一条
  getByNext: (data: any) => axios.post(`${api}/deviceAutodeteNew/getInfoByNext`, data),
  // 设备运营监控统计报表-设备详情-自检-上下一页
  // getDeviceMonitorSelfCheckByNext: (param: any) => axios.post(`${api}/deviceAutodeteNew/getStatisInfoByNext`, param),
  getProductStatisInfoByNext: (param: any) => axios.post(`${api}/deviceAutodeteNew/getProductStatisInfoByNext`, param),
  // 设备运营监控统计报表-故障详情-自检-上下一页
  // getDeviceMonitorFaultSelfCheckByNext: (param: any) => axios.post(`${api}/deviceAutodeteNew/getStatisFaultInfoByNext`, param)
  getProductStatisFaultInfoByNext: (param: any) => axios.post(`${api}/deviceAutodeteNew/getProductStatisFaultInfoByNext`, param),
  //导出
  export:(data: any) => axios.post(`${api}/deviceAutodeteNew/exportPageBy`, data),
}

// 历史自检
export const pastSelfCheckService = {
  // 分页查询设备历史自检(终端)
  getByPage: (data: any) => axios.post(`${api}/deviceAutodete/getPageBy`, data),
  // 分页查询设备历史自检(设备端)
  getByPaged: (data: any) => axios.post(`${api}/deviceAutodete/getDeviceTerPageBy`, data),
  // 查询设备历史自检详情
  getById: (data: Id) => axios.post(`${api}/deviceAutodete/getInfoBy`, data),
  // 查询设备历史自检一条/下一条的详情
  getByNext: (data: any) => axios.post(`${api}/deviceAutodete/getInfoByNext`, data),
  //导出
  export:(data: any) => axios.post(`${api}/deviceAutodete/exportPageBy`, data),
}

// 设备拍照查询
export const photoService = {
  // 分页查询设备拍照
  getByPage: (data: any) => axios.post(`${api}/dvrUploadfile/getPageBy`, data)
}
// 图商拍照查询
export const picturePhoto = {
  // 分页查询图商拍照
  getByPage: (data: any) => axios.post(`${api}/uploadPicInfo/getPageBy`, data)
}

// 终端管理
export const deviceService = {
  // 分页查询终端
  getByPage: (data: any) => axios.post(`${api}/terminal/deviceInfo/page`, data),
  export: (data: any) => axios.post(`${api}/terminal/deviceInfo/exportDevice`, data),
  getById: (data: any) => axios.post(`${api}/terminal/deviceInfo/detail`, data),
  updateById: (data: any) => axios.post(`${api}/terminal/deviceInfo/update`, data),
  add: (data: any) => axios.post(`${api}/terminal/deviceInfo/add`, data),
  batchAdd: (data: any) => axios.post(`${api}/terminal/deviceInfo/batchAdd`, data),
  batchUpdate: (data: any) => axios.post(`${api}/terminal/deviceInfo/batchUpdate`, data),
  batchSendCommand: (data: any) => axios.post(`${api}/deviceCommand/batchSendCommand`, data),
  batchUpgrade: (data: any) => axios.post(`${api}/deviceUpgrade/batchSendUpgrade`, data),
  // 批量终端参数修改
  batchUpgradeParam: (data: any) => axios.post(`${api}/terminal/serverConfig/batchUpdate`, data),
  // 设备信息导入
  importDevice: (data: any) => axios.post(`${api}/terminal/deviceInfo/importDevice`, data),
  // 查询软件版本列表，用于批量终端升级的时候选择软件包
  getDeviceVersionList: () => axios.post<{
    softwareTypeName: string
    packageTypeName: string
    versionNum: string
  }[]>(`${api}/deviceVersion/list`)
}

// header部分验收故障列表功能相关接口
export const headerFaultListService = {
  getByPage: (data: any) => axios.post(`${api}/installFault/page`, data),
  // 点击处理
  operate: (data: any) => axios.post(`${api}/installFault/handle`, data),
  // 查询提醒数量
  getCount: () => axios.post(`${api}/installFault/count`)
}

// 白名单管理
export const whiteList = {
  // 白名单管理查询
  getByPage: (data: any) => axios.post(`${api}/whitelist/getPageBy`, data),
  // 批量添加白名单
  addWhiteList: (data: any) => axios.post(`${api}/whitelist/add`, data),
  // 批量修改白名单
  updateWhiteList: (data: any) => axios.post(`${api}/whitelist/update`, data),
  // 批量删除白名单
  deleteWhiteList: (data: any) => axios.post(`${api}/whitelist/deleteById`, data),
}

// 黑名单管理
export const blackList = {
  // 黑名单管理查询
  getByPage: (data: any) => axios.post(`${api}/blacklist/getPageBy`, data),
  // 批量添加黑名单
  addblackList: (data: any) => axios.post(`${api}/blacklist/add`, data),
  // 批量删除黑名单
  deleteblackList: (data: any) => axios.post(`${api}/blacklist/deleteById`, data),
}

// 白名单管理
export const enterpriseWhiteList = {
  // 白名单管理查询
  getByPage: (data: any) => axios.post(`${api}/whitelistCompany/getPageBy`, data),
  // 批量添加白名单
  addWhiteList: (data: any) => axios.post(`${api}/whitelistCompany/add`, data),
  // 批量修改白名单
  updateWhiteList: (data: any) => axios.post(`${api}/whitelistCompany/update`, data),
  // 批量删除白名单
  deleteWhiteList: (data: any) => axios.post(`${api}/whitelistCompany/deleteById`, data),
}