import React, { PureComponent, Children, CSSProperties } from 'react'
import { MapProps, LngLat } from './interface'
import APILoader from './APILoding'
import { toLngLat, pointConvert, EventListener } from './util/comm'
import { isEqual } from 'lodash-es'
import classnames from 'classnames'
import { towgs84 } from './util/convert'

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%'
}
const wrapperStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    position: 'relative'
}

interface MyState {
    mapLoaded: boolean
    type: string
}

export default class index extends PureComponent<MapProps, MyState> {

    constructor(props: MapProps) {
        super(props)
        this.map = null
        this.state = {
            mapLoaded: false,
            type: props.type || 'amap'
        }
        if (typeof window !== 'undefined') {
            this.initMap()
        }

    }

    map: any
    mapWrapper: HTMLDivElement | null
    center: LngLat | undefined

    initMap() {
        if (typeof window !== 'undefined') {
            new APILoader({
                type: this.props.type || 'amap',
                v: this.props.v,
                key: this.props.key,
                callbackName: this.props.callbackName,
                amapUI: this.props.type === 'amap' ? this.props.amapUI === false ? false : true : false
            }).load()?.then(() => {
                this.createInstance(this.props)
                this.setState({
                    mapLoaded: true
                })
            })
        }
    }

    componentWillReceiveProps(nextProps: MapProps) {

        if (nextProps.type !== this.props.type) {
            this.destroyMap()
            this.reloadMap(nextProps)
        }
        if (nextProps.center && !isEqual(nextProps.center, this.props.center)) {
            if (this.map) {
                this.moveCenter(nextProps)
            }
        }
        if (nextProps.zoom && !isEqual(nextProps.zoom, this.props.zoom)) {
            if (this.map) {
                this.setZoom(nextProps.zoom)
            }
        }
        if (this.map) {
            this.getViewport(nextProps)
        }
    }

    createInstance(props: MapProps) {
        if (!this.map) {
            this.createMap(props)
        }
    }

    async reloadMap(props: MapProps) {
        this.setState({
            type: props.type || 'amap'
        })
        new APILoader({
            type: props.type || 'amap',
            v: props.v,
            key: props.key,
            callbackName: this.props.callbackName,
            amapUI: this.props.type === 'amap' ? this.props.amapUI === false ? false : true : false
        }).load()?.then(() => {
            this.createMap(props)
        })
    }

    createMap(props: MapProps) {
        const { center, type, onMoveend, onZoomend } = props
        const _type = type || 'amap'

        if (_type === 'amap') {
            this.map = new (window as any).AMap.Map(this.mapWrapper, {
                zoom: props.zoom || 8,
                resizeEnable: true
            })
            // if (center) {
            //     this.map.setCenter(toLngLat(center, _type))
            // }
            this.initCenter(props)
            const toolBar = new (window as any).AMap.ToolBar({
                visible: true,
            })

            this.map.addControl(toolBar);
            this.map.on('click', () => {
                if (this.props.onClick) {
                    this.props.onClick()
                }
            })
        } else if (_type === 'baidu') {

            this.map = new (window as any).BMap.Map(this.mapWrapper)
            this.map.enableScrollWheelZoom()
            // 默认定位到北京
            // let point: [number, number] = center ? [center.lng, center.lat] : [109.250399, 34.344602]
            // this.map.centerAndZoom(toLngLat(point, _type), props.zoom || 8)
            this.initCenter(props)

            if (!center) {
                this.getLocation()
            }

            if (props.theme) {
                this.map.setMapStyle({ styleJson: props.theme })
            }

            require('./util/baidu/drawingManager')
            require('./util/baidu/rule')
            require('./util/baidu/rectangleZoom')
            require('./util/baidu/infoBox')
        }

        EventListener(this.props.type!, this.map, 'moveend', () => {
            if (onMoveend) {
                const center = this.map.getCenter()

                onMoveend(towgs84([center.lng, center.lat], type!))
            }
        })
        EventListener(this.props.type!, this.map, 'zoomend', () => {
            if (onZoomend) {
                onZoomend(this.map.getZoom())
            }
        })

        // install map plugins
        this.props.onInstanceCreated && this.props.onInstanceCreated(this.map, _type)

        if (props.viwepoert) {
            this.getViewport(props)
        }

        this.setState({
            mapLoaded: true
        })
    }

    destroyMap() {
        if (this.map && this.map.destroy) {
            this.map.destroy()
        }

        this.setState({
            mapLoaded: false
        })
    }

    async getViewport(props: MapProps) {
        if (props.type === 'baidu' && props.viwepoert && props.viwepoert.length) {
            const points = await pointConvert(props.viwepoert!, props.type)
            this.map.setViewport(points)
        }
        if (props.type === 'amap' && props.viwepoert) {
            this.map.setFitView()
        }
    }

    renderChildren() {
        if (this.props.children) {
            return Children.map(this.props.children, (child: any) => {
                if (child) {
                    return React.cloneElement(child, {
                        __map__: this.map,
                        type: this.state.type
                    })
                }
                return child
            })
        }
        return null
    }



    async initCenter(props: MapProps) {
        if (this.state.type === 'baidu') {
            const point = props.center ? await pointConvert([[props.center.lng, props.center.lat]], props.type) : [toLngLat([114.057939, 22.543527])]
            this.map.centerAndZoom(point[0], props.zoom || 8)
        } else if (this.state.type === 'amap' && props.center) {
            const point = await pointConvert([[props.center.lng, props.center.lat]], props.type)
            this.map.setCenter(point[0])
        }
    }

    async moveCenter(props: MapProps) {
        if (this.state.type === 'baidu' && props.center) {
            const point = await pointConvert([[props.center.lng, props.center.lat]], props.type)
            this.map.panTo(point[0])
        } else if (this.state.type === 'amap' && props.center) {
            const point = await pointConvert([[props.center.lng, props.center.lat]], props.type)
            this.map.setCenter(point[0])
        }
    }

    setZoom(zoom: number) {
        if (this.state.type === 'baidu' && zoom) {
            this.map.setZoom(zoom)
        } else if (this.state.type === 'amap' && zoom) {
            this.map.setZoom(zoom)
        }
    }

    /**
     * 根据浏览器定位
     * @see http://lbsyun.baidu.com/jsdemo.htm#i8_1 百度
     * @see https://lbs.amap.com/api/javascript-api/example/location/browser-location 高德
     */
    getLocation() {
        if (this.state.type === 'baidu' && (window as any).BMap) {
            const geolocation = new (window as any).BMap.Geolocation();
            geolocation.getCurrentPosition((r: any) => {
                if (geolocation.getStatus() === 0) {
                    this.map.panTo(r.point);
                } else {

                }
            }, { enableHighAccuracy: true })
        } else if (this.state.type === 'amap' && (window as any).AMap) {
            (window as any).AMap.plugin('AMap.Geolocation', () => {
                var geolocation = new (window as any).AMap.Geolocation({
                    enableHighAccuracy: true,//是否使用高精度定位，默认:true
                    timeout: 10000,          //超过10秒后停止定位，默认：5s
                    buttonPosition: 'RB',    //定位按钮的停靠位置
                    buttonOffset: new (window as any).AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                    zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点
                });
                geolocation.getCurrentPosition((status: any, result: any) => {
                    if (status === 'complete') {
                        this.map.setCenter(result.position)
                    } else {
                        console.log('map --- GD --- getlocation', result);
                    }
                });
            })
        }
    }

    render() {

        return (
            <div style={ wrapperStyle } className={ classnames('tx-map', this.props.className) }>
                {
                    this.state.type === 'baidu' ?
                        <>
                            <div ref={ (div) => {
                                this.mapWrapper = div
                            } } style={ containerStyle }></div>
                            <div>
                                { this.state.mapLoaded ? this.renderChildren() : null }
                            </div>
                        </> : null
                }
                {
                    this.state.type === 'amap' ?
                        <>
                            <div ref={ (div) => { this.mapWrapper = div } } style={ containerStyle }></div>
                            <div>
                                { this.state.mapLoaded ? this.renderChildren() : null }
                            </div>
                        </> : null
                }
            </div>
        )
    }
}
