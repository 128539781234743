import React, {useEffect, useRef, useReducer, CSSProperties} from 'react'
import {useInterval} from "react-use"
import * as echarts from "echarts";
import "echarts-gl";
import {EChartOption} from 'echarts/lib/echarts'
import {random, debounce} from "lodash-es"
import china from "map/json/china.json"

import 'echarts/lib/chart/map'

export interface EchartsProps extends EChartOption {
  className?: string
  style?: CSSProperties
}

const Reducer = (state: any, action: any) => {
  const {type, data} = action

  switch (type) {
    case 'create':
      state = data
      return state
    case 'update':
      if (state) {
        state.resize()
      }
      return state
    case 'dispose':
      if (state) {
        state.dispose()
      }
      state = null
      return state
    case 'clear':
      if (state) {
        const options = state.getOption()
        options.series[1].data.map((i: any) => {
          i.label.show = false
        })
        state.dispatchAction({
          type: 'unselect',
          seriesIndex: 2,
          dataIndex: data
        })
        state.setOption(options, true)
      }
      return state
    case 'showTip':
      if (state) {
        state.dispatchAction({
          type: 'showTip',
          seriesIndex: 2,
          position: 'inside',
          dataIndex: data
        })
        state.dispatchAction({
          type: 'select',
          seriesIndex: 2,
          position: 'inside',
          dataIndex: data
        })
      }
      return state
    default:
      return state
  }
}

const EchartsMapComponent = (props: EchartsProps) => {
  const {className, ...extra} = props

  const ehcartsElement: any = useRef()

  const [myChart, dispatch] = useReducer(Reducer, null)

  const autoShow = useRef<boolean>()
  autoShow.current = true

  const dataIndex = useRef<number>(0)


  useEffect(() => {

    window.addEventListener('resize', resize)
    window.addEventListener('mousemove', move)

    return () => {
      dispose()
      window.removeEventListener('resize', resize)
      window.removeEventListener('mousemove', move)
    }
  }, [])

  useEffect(() => {
    echarts.registerMap("china", {geoJSON: china});
    const selfChart: any = echarts.init(ehcartsElement.current)
    selfChart.setOption({...extra})
    dispatch({type: 'create', data: selfChart})
  }, [ehcartsElement.current])

  useEffect(() => {
    if (myChart) {
      myChart.dispatchAction({
        type: 'hideTip'
      })
      /**
       * 在渲染前后手动禁用地图缩放，
       * 防止在渲染期间的缩放导致散点跟地图之间发生错位
       */
      myChart.setOption({geo: {roam: false}})
      myChart.setOption({...extra})
      setTimeout(() => {
        myChart.setOption({geo: {roam: true}})
      }, 500)
    }
  }, [props.series])

  // 轮播tooltip 老版
  // useInterval(() => {
  //   if (myChart) {
  //     myChart.dispatchAction({
  //       type: 'showTip',
  //       seriesIndex: 2,
  //       position: 'inside',
  //       dataIndex: Math.floor(Math.random()*10)
  //     })
  //   }
  // }, 5000)

  // 轮播tooltip new
  // useInterval(() => {
  //   if (myChart) {
  //     myChart.dispatchAction({
  //       type: 'showTip',
  //       seriesIndex: 2,
  //       position: 'inside',
  //       dataIndex: Math.floor(Math.random() * 10)
  //     })
  //   }
  // }, 800)

  // 轮播label
  let labelIndex = 0
  useInterval(() => {
    if (myChart && autoShow.current) {
      const options = myChart.getOption()
      if (options.series[1].data.length) {
        options.series[1].data.map((i: any) => {
          i.label.show = false
        })
        options.series[1].data[labelIndex].label.show = true
        myChart.setOption(options, true)

        dataIndex.current = random(0, 31)

        if (options.series[2].data[dataIndex.current]?.value) {
          dispatch({type: 'showTip',data:dataIndex.current})
        }

        if (labelIndex >= options.series[1].data.length - 1) {
          labelIndex = 0
        } else {
          labelIndex++
        }
      }
    }
  }, 3000)

  const resize = () => {
    dispatch({type: 'update'})
  }

  const dispose = () => {
    dispatch({type: 'dispose'})
  }

  const move = debounce(() => {
    dispatch({type: 'clear',data:dataIndex.current})

    if (autoShow.current) {
      autoShow.current = false
      setTimeout(() => {
        autoShow.current = true
      }, 10000)
    }
  }, 1000, {
    leading: true,
    trailing: false
  })

  return (
    <div
      ref={ehcartsElement}
      className={className}
      style={{width: '100%', height: '100%', ...props.style}}
    />
  )
}

export default EchartsMapComponent
