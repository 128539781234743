import {baseURL} from './index'
import axios from '../axios'

/**
 * 获取车辆列表坐标信息
 */
export const getVehicleGps = () => axios.post<{
  id: string,
  latitude: string,
  longitude: string
}[]>(`${baseURL}/webapi/bigScreen/getVehicleGps`, {})

/**
 * 详情
 */
export const getVehicleBigScreenInfo = (param: { vehicleId: string }) => axios.post<{
  id: string
  vehicleNo: string
  vehicleSerial: string
  companyName: string
  address: string
  latitude: number
  longitude: number
  gpsTime: string
}>(`${baseURL}/webapi/bigScreen/getVehicleBigScreenInfo`, param)

/**
 * 车辆总数等信息接口
 */
export const getVehicleBigScreenNum = () => axios.post<{
  id: string
  vehicleNum: number
  mileageNum: number
  companyNum: number
  duration: number
  onlineVehicleNum: number
  onlineVehicleRatio: string
  newVehicleNum: number
  newVehicleRatio: string
  breakdownVehicleNum: number
  breakdownVehicleRatio: string
  upgradeNum: number
  upgradeRatio: string
  examineNum: number
}>(`${baseURL}/webapi/bigScreen/getVehicleBigScreenNum`, {})

/**
 * 大屏标签接口
 */
export const getBigScreenLabel = () => axios.post<{
  id: string
  labelTypeName: string
  labelTypeNum: number
}[]>(`${baseURL}/webapi/bigScreen/getBigScreenLabel`, {})

/**
 * 查询省份统计信息
 */
export const getAreaVehicle = () => axios.post<{
  areaName: string
  areaCode: string
  vehicleNum: number
  onlineRate: string
}[]>(`${baseURL}/webapi/bigScreen/getAreaVehicle`, {})

/**
 * 获取在线车辆信息，用于前端随机弹框
 */
export const getOnlineVehicleTop = () => axios.post<{
  id: string
  vehicleNo: string
  companyName: string
  driverName: string
  simNo: string,
  address: string,
  gpsDate: string,
  latitude: number,
  longitude: number
}[]>(`${baseURL}/webapi/bigScreen/getOnlineVehicleTop`, {})
