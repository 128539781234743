/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-08-03 16:48:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-20 17:21:03
 */
export default {
  confirm: '确定',
  add: '新增',
  delete: '删除',
  update: '修改',
  cancel: '取消',
  operate: '操作',
  query: '查询',
  reset: '重置',
  export: '导出',
  columnSetting: '列设置',
  view: '查看',
  send: '发送',
  remark: '备注',
  serialNumber: '序号',
  startTime: '开始时间',
  endTime: '结束时间',
  // 选择
  'comm101': 'Please select {value}',
  // 输入
  'comm201': 'Please enter{value}',
  'comm202': 'Please enter{value}{value2}',
  'comm203': 'Please enter{value}{value2}{value3}',
  'comm211': 'Minimum input{value}character',
  'comm212': 'Maximum input{value}character',
  // 公用
  'comm901': 'name',
  'comm902': '/',
  'comm903': 'or',
  'comm904': 'whole',
  'comm905': 'onLine',
  'comm906': 'offLine',
  'comm907': '行驶',
  'comm908': '停车',
  'comm909': '报警',
}