import React, { useState } from "react";
import { Input, Button, Radio, Form, message, Tooltip } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { languageType } from "locale";
import VerificationModal from "./modal";
import { getUserMenu } from "server/buc/menu";
import { login } from "server/buc/login";
import * as store from "store";
import { useHistory } from "react-router-dom";
import { setLocal, changeProjectName } from "util/comm";

const { Group, Button: RadioButton } = Radio;
const { Item } = Form;

const Login = () => {
  const {
    menuStore,
    intlStore,
    intlStore: { lang },
    loginStore: { updateLoginStore, loginLoading },
    userStore: { updateUserInfo },
  } = store;

  /** 是否大写 */
  const [isCapital, setIsCapital] = useState(false);
  /** 是否登录时出错（用户名或密码错误），错误一次之后才显示图片验证模块 */
  const [loginError, setLoginError] = useState(false);
  const { formatMessage: f } = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();

  const changeLange = (e: RadioChangeEvent) => {
    const {
      target: { value },
    } = e;
    intlStore.updateIntl(value);
  };

  const onFinish = async (values: any) => {
    if (loginError) {
      updateLoginStore("showVeri", true);
    } else {
      updateLoginStore("loginLoading", true);
      message.loading(f({ id: "tx090002", description: "正在登陆中..." }), 0);
      const data = await login({
        language: lang,
        platformId: "00-01-01-01-00",
        ...values,
      });
      updateLoginStore("loginLoading", false);
      if (data && data.code === 0) {
        message.destroy();
        updateUserInfo(data.data);
        message.success(f({ id: "tx090003", description: "登录成功" }), 2);

        const result = await getUserMenu({});
        if (result && result.data.length) {
          setLocal("tx_menu", result.data);
          menuStore.updateMenu(result.data);
          history.push(menuStore.jumpFirstPage());
        } else {
          message.warning(
            f({ id: "tx000225", description: "请联系管理员配置权限菜单" })
          );
        }
      } else {
        form.resetFields();
        setLoginError(true);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    const {
      errorFields: [{ errors }],
    } = errorInfo;
    message.warning(errors);
  };

  const TYPE = process.env.REACT_APP_TYPE!;
  let qrcode;
  if (TYPE === "concrete") {
    qrcode = (
      <img
        style={{ width: 107, height: 125, marginTop: 5 }}
        src={"/images/login/qrcode_tiantong.png"}
        alt={""}
      />
    );
  } else if (TYPE === "tiantong") {
    qrcode = null;
  } else {
    qrcode = (
      <img
        style={{ width: 107, height: 125, marginTop: 5 }}
        src={"/images/login/qrcode_tianxun.png"}
        alt={""}
      />
    );
  }

  return (
    <>
      <Form
        form={form}
        className="login-container"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div
          className={`login-title ${
            lang === languageType.zh ? "login-title-zh" : "login-title-en"
          }`}
        >
          {changeProjectName().loginName}
        </div>
        <Group
          className="login-language"
          defaultValue={lang}
          buttonStyle="solid"
          size="small"
          onChange={changeLange}
        >
          <RadioButton value={languageType.zh}>中文</RadioButton>
          {/* <RadioButton value={ languageType.en }>English</RadioButton> */}
        </Group>
        <Item
          name="userAccount"
          rules={[
            {
              required: true,
              message: f(
                { id: "tx000202", description: "请输入账号" },
                { value: f({ id: "tx090005" }) }
              ),
            },
            {
              max: 30,
              type: "string",
              message: f(
                { id: "tx000206", description: "最多输入30个字符" },
                { value: 30 }
              ),
            },
          ]}
        >
          <Input
            size="large"
            className="login-input"
            prefix={<UserOutlined className="login-input-icon" />}
            placeholder={f(
              { id: "tx000202", description: "请输入账号" },
              { value: f({ id: "tx090005" }) }
            )}
          />
        </Item>

        <Item
          name="password"
          rules={[
            {
              required: true,
              message: f(
                { id: "tx000202", description: "请输入密码" },
                { value: f({ id: "tx090006" }) }
              ),
            },
            {
              max: 30,
              type: "string",
              message: f(
                { id: "tx000206", description: "最多输入30个字符" },
                { value: 30 }
              ),
            },
          ]}
        >
          <Input.Password
            size="large"
            className="login-input"
            visibilityToggle={false}
            onKeyPress={(e) => {
              const valueCapsLock = e.charCode;
              var valueShift = e.shiftKey
                ? e.shiftKey
                : valueCapsLock === 16
                ? true
                : false;
              if (
                (valueCapsLock >= 65 && valueCapsLock <= 90 && !valueShift) ||
                (valueCapsLock >= 97 && valueCapsLock <= 122 && valueShift)
              ) {
                setIsCapital(true);
              } else {
                setIsCapital(false);
              }
            }}
            prefix={
              <Tooltip
                title={f({ id: "tx090009", description: "大写锁定已打开" })}
                visible={isCapital}
                getPopupContainer={() =>
                  document.querySelector(".login-page-box") || document.body
                }
                placement="topLeft"
                color="#0b1f5b"
              >
                <LockOutlined className="login-input-icon" />
              </Tooltip>
            }
            placeholder={f(
              { id: "tx000202", description: "请输入密码" },
              { value: f({ id: "tx090006" }) }
            )}
          />
        </Item>

        <Button
          size="large"
          block
          className="login-button"
          type="primary"
          htmlType="submit"
          loading={loginLoading}
        >
          {f({ id: "tx090001", description: "登录" })}
        </Button>
        {/*<div className="login-app-download-QRcode">*/}
        {/*  {f({ id: 'tx090010', description: '小程序下载二维码' })}*/}
        {/*</div>*/}
        <p className="version-number-p">v2.1.0</p>
      </Form>
      <div className="login-weapp-download-QRcode">{/*{qrcode}*/}</div>
      <VerificationModal form={form} />
    </>
  );
};

export default observer(Login);
