import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './index.less';

import App from './App'
import Login from 'view/login'
import NotFound from 'view/404'
import EchartsMap from 'view/largeScreen/echartsMap'
import BaiduMap from 'view/largeScreen/baiduMap'

import DevOlMap from 'view/devOlMap'
import CommonPage from "./components/commonPage"

function Page() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={ () => <Redirect to="/login" push /> } />
        <Route path="/login" component={ Login } />
        <Route path="/index" component={ App } />
        <Route path="/404" component={ NotFound } />
        <Route path="/largeScreen" component={ EchartsMap } />
        <Route path="/largeScreenBaidu" component={ BaiduMap } />
        <Route path="/devMap" component={ DevOlMap } />
        <Route path="/commonPage" component={ CommonPage } />
        <Route component={ NotFound } />
      </Switch>
    </Router>
  )
}

export default Page
