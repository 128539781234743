/**
 * TODO 这个组件目前不太规范，需要整理一下
 * TODO 这个组件的大屏地图部分功能有内存泄漏，需要处理
 */

import React, {useEffect, useRef, useReducer, CSSProperties} from 'react'
import * as echarts from "echarts";
import "echarts-gl";
import {EChartOption} from 'echarts/lib/echarts'
import {BampProps} from './interface'
import {MapDarkStyle} from './theme'
import china from "map/json/china.json"

// TODO 临时放这，请求不应该放这里
import {getVehicleBigScreenInfo} from 'server/web/largeScreen'

// // 引入dataset
// import 'echarts/lib/component/dataset'
// // 引入柱状图
// import 'echarts/lib/chart/bar'
// // 引入折线图
// import 'echarts/lib/chart/line'
// // 引入折线图
// import 'echarts/lib/chart/graph'
// // 引入饼图
// import 'echarts/lib/chart/pie'
// // 引入带有起点和终点信息的线
// import 'echarts/lib/chart/lines'
// // 引入涟漪特效动画的散点
// import 'echarts/lib/chart/effectScatter'
// // 引入涟漪特效动画的散点
// import 'echarts/lib/chart/scatter'
// 引入地图
import 'echarts/lib/chart/map'
// // 引入中国地图数据
// import 'echarts/map/js/china'
//
// // 引入提示框和标题组件
// import 'echarts/lib/component/tooltip'
// import 'echarts/lib/component/title'
// import 'echarts/lib/component/geo'
// import 'echarts/lib/component/visualMap'
import {toLngLat} from 'components/map/util/comm'

export interface EchartsProps extends EChartOption {
  className?: string
  aibpoints?: Array<[number, number, string]>
  style?: CSSProperties
  bmap?: BampProps
}

require("echarts/dist/extension/bmap")

const Reducer = (state: any, action: any) => {
  const {type, data} = action

  switch (type) {
    case 'create':
      state = data
      return state
    case 'update':
      if (state) {
        state.resize()
      }
      return state
    case 'dispose':
      if (state) {
        state.dispose()
      }
      state = null
      return state
    default:
      return state
  }
}

const Index = (props: EchartsProps) => {
  const {className, ...extra} = props

  const ehcartsElement: any = useRef()

  const [myChart, dispatch] = useReducer(Reducer, null)
  const _bmap: any = useRef()


  useEffect(() => {

    window.addEventListener('resize', resize)

    return () => {
      dispose()
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    echarts.registerMap("china", {geoJSON: china});
    const selfChart: any = echarts.init(ehcartsElement.current)
    if ('bmap' in props) {
      selfChart.setOption({...extra}, true)
      var bmap = selfChart.getModel().getComponent('bmap').getBMap()
      _bmap.current = bmap
      bmap.setMapStyleV2({styleJson: MapDarkStyle})
      dispatch({type: 'create', data: selfChart})
    } else {
      selfChart.setOption({...extra})
      dispatch({type: 'create', data: selfChart})
    }
  }, [ehcartsElement.current])

  useEffect(() => {
    if (myChart) {
      myChart.setOption({...extra})
      if (extra.bmap && extra.bmap.isViewport && _bmap.current) {
        if (extra.bmap?.viewport && extra.bmap.viewport.length) {
          _bmap.current.setViewport(extra.bmap.viewport?.map(item => toLngLat(item, 'baidu')))
        }
      }
    myChart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: 0
    })
    }
  }, [props.series])

  useEffect(() => {
    if (myChart && _bmap.current) {
      const {aibpoints = []} = props
      const options = {
        // @ts-ignore
        size: BMAP_POINT_SIZE_SMALL,
        // @ts-ignore
        shape: BMAP_POINT_SHAPE_CIRCLE,
        color: '#287d02'
      }
      let allOverlay = _bmap.current.getOverlays();
      for (let i = 0; i < allOverlay.length; i++) {
        // 有root那个overlay如果被remove，会报错
        if (!allOverlay[i].hasOwnProperty('_root')) {
          _bmap.current.removeOverlay(allOverlay[i]);
        }
      }

      if (aibpoints.length) {
        const temp = aibpoints.map((i) => {
          // @ts-ignore
          let point = new BMap.Point(i[0], i[1])
          point.id = i[2]
          return point
        })
        // @ts-ignore
        let pointCollection = new BMap.PointCollection(temp, options);
        pointCollection.addEventListener('click', function (e: any) {
          getVehicleBigScreenInfo({vehicleId: e.point.id}).then(res => {
            if (res) {
              const info = res.data
              var searchInfoWindow3 = new BMapLib.SearchInfoWindow(_bmap.current, `车牌号：${info.vehicleNo || '-'}<br/>车辆编号：${info.vehicleSerial || '-'}<br/>所属公司：${info.companyName || '-'}<br/>地址：${info.address || '-'}<br/>时间：${info.gpsTime || '-'}`, {
                title: '车辆信息', //标题
                width: 250, //宽度
                panel: "panel", //检索结果面板
                enableAutoPan: true, //自动平移
                searchTypes: []
              });

              // @ts-ignore
              searchInfoWindow3.open(new BMap.Point(e.point.lng, e.point.lat))
            }
          })
        })
        _bmap.current.addOverlay(pointCollection)
      }
    }
  }, [props.aibpoints])

  useEffect(() => {
    if (myChart && _bmap.current && props.bmap?.center) {
      // @ts-ignore
      _bmap.current.setCenter(new BMap.Point(props.bmap?.center[0], props.bmap?.center[1]))
      _bmap.current.setZoom(props.bmap.zoom)
    }
  }, [props.bmap?.center, props.bmap?.zoom])

  const resize = () => {
    dispatch({type: 'update'})
  }

  const dispose = () => {
    dispatch({type: 'dispose'})
  }

  return (
    <div
      ref={ehcartsElement}
      className={className}
      style={{width: '100%', height: '100%', ...props.style}}
    >
    </div>
  )
}

export default Index
