import React, { useState, useEffect, FC } from 'react'
import { RightOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import { isEqual } from 'lodash-es'
import './index.less';

interface StretchProps {
  /** 抽屉方向 默认right */
  direction?: 'right' | 'left'
  className?: string
  children?: JSX.Element | JSX.Element[]
  /** 控制抽屉宽度 */
  width?: number
}

interface selfStyleProps {
  right?: string | number,
  left?: string | number
}

const defaultWidth = 250

const areEqual = (prevProps: StretchProps, nextProps: StretchProps) => {
  return isEqual(prevProps.children, nextProps.children)
}

const Index: FC<StretchProps> = React.memo((props: StretchProps) => {
  const { direction, width } = props

  const [visible, setVisible] = useState<boolean>(true)
  const [selfStyle, setSelfStyle] = useState<selfStyleProps>({ right: -defaultWidth })

  useEffect(() => {
    if (direction === 'left') {
      setSelfStyle({
        left: width ? -width : -defaultWidth
      })
    }
  }, [])

  useEffect(() => {
    if (visible) {
      setSelfStyle({})
    } else {
      if (direction === 'left') {
        setSelfStyle({
          left: width ? -width : -defaultWidth
        })
      } else {
        setSelfStyle({
          right: width ? -width : -defaultWidth
        })
      }
    }
  }, [visible])

  const onClick = () => {
    setVisible(!visible)
  }

  return (
    <div
      className={ classnames('tx-stretch', props.className, {
        'stretch-right': direction === 'right' || typeof direction === 'undefined',
        'stretch-left': direction === 'left',
      }) }
      style={ {
        width: width || defaultWidth,
        ...selfStyle
      } }
    >
      <div className='tx-stretch-content'>
        <div
          className='tx-stretch-btn'
          onClick={ onClick }
        >
          <RightOutlined className={ classnames('tx-stretch-btn-icon', { 'btn-rotate': visible }) } />
        </div>
        <div className='tx-stretch-body'>
          {
            props.children
          }
        </div>
      </div>
    </div>
  )
}, areEqual)

export default Index
