import React, { useState, useCallback } from "react";
import { Select } from "antd";
import { getLinePoint } from "server/amap";
import { useIntl } from "react-intl";

import { debounce } from "lodash-es";

interface TipProps {
  className?: string;
  onChange?: (posotion: string) => void;
  onTipSelect?: (val: any) => void;
  [key: string]: any;
}

const Index = (props: TipProps) => {
  const { onChange, onTipSelect, ...extra } = props;

  const { formatMessage: f } = useIntl();
  const [options, setOptions] = useState<Array<any>>([]);

  const onSearch = async (value: string) => {
    if (value) {
      const result: any = await getLinePoint({
        roadName: value,
      });

      if (result && result.data && result.data.length) {
        // 获取有经纬度的值
        const hasPosition = result.data.filter(
          (item: any) => item.name && item.location
        );
        setOptions(hasPosition);
      }
    }
  };

  const onSelect = (value: string) => {
    if (onChange) {
      onChange(value);
    }
    if (onTipSelect) {
      let arr = options.filter((tt) => !!tt && tt.location === value);
      if (arr && arr.length) {
        onTipSelect(arr[0]?.location.split(";")?.[0]);
      }
    }
  };

  return (
    <Select
      showSearch
      virtual={false}
      placeholder={f({ id: "tx000220" })}
      onSearch={debounce(onSearch, 500)}
      onSelect={onSelect}
      filterOption={false}
      {...extra}
    >
      {options.map((item, index: number) => {
        return (
          <Select.Option value={item.location} key={index}>
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default Index;
