import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'mobx-react';
import * as stores from './store'
import Intl from './intl';
import Page from './page';

// if (process.env.NODE_ENV === 'development') {
//   import('./mock').then(()=>{
//     console.log('mock loaded!')
//   })
// }

ReactDOM.render(
  <Provider { ...stores }>
    <AppContainer>
      <Intl>
        <Page />
      </Intl>
    </AppContainer>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
