import React, { useRef, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import { Dropdown, Tooltip } from 'antd';
import Video from './video'
import AliIcon from 'components/ailiIcon';
import useSetState from 'util/useSetState';
import SettingMenu from './components/settingMenu'
import ScreenMenu from './components/screenMenu'
import { useToggle, useFullscreen, useTimeout } from 'react-use'
import { cloneDeep, isEqual, uniqBy } from 'lodash-es'
import { BroadcastProps, videoItem, playObj } from './interface';
import './index.less'

/**
 * 通道数
 */
const passagewayType = [1, 4, 6, 8, 9, 16]

export type PassagewayType = typeof passagewayType[number]

interface MyState {
  /**
   * video Url组件数组
   */
  videoList: Array<videoItem | null>
  /**
   * 通道数
   */
  passagewayNo: PassagewayType
  videoScreen: number
  time: number
  /** 自动关闭视频状态 0: 销毁定时器， 1：触发回调函数 2: 重置定时器*/
  autoCloseStatus: number
  /** 自动关闭视频时间 */
  autoCloseTime: number
}


const areEqual = (prevProps: BroadcastProps, nextProps: BroadcastProps) => {
  return isEqual(prevProps, nextProps)
}

function Broadcast(props: BroadcastProps) {
  const { videoList, toolTitleIntls } = props

  const [state, setState] = useSetState<MyState>({
    videoList: new Array(16).fill(null),
    passagewayNo: 4,
    videoScreen: 1,
    time: 0,
    autoCloseTime: 5,
    autoCloseStatus: 0
  })

  const broadcastContainer: any = useRef()
  const bateTimer: any = useRef()
  const autoCloseTimer: any = useRef()
  const sessionIds: any = useRef([])
  const _videoList: any = useRef([])
  const endIndex: any = useRef(0)

  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(broadcastContainer, show, { onClose: () => toggle(false) });

  useEffect(() => {
    initVideoList()
  }, [])

  useEffect(() => {
    setState({
      autoCloseTime: props.autoCloseTime
    })
  }, [props.autoCloseTime])

  useEffect(() => {
    if (props.closeVideoNumber) {
      if (props.stopAllVideo) {
        props.stopAllVideo(state.videoList)
      }
      initVideoList()
    }
  }, [props.closeVideoNumber])

  useEffect(() => {
    if (props.passageway) {
      setState({
        passagewayNo: props.passageway
      })
    }
  }, [props.passageway])

  useEffect(() => {

    if (videoList && videoList.length) {
      setState({ autoCloseStatus: 2 })
      handleVideoList(videoList)
    }
  }, [videoList])

  useEffect(() => {
    if (state.autoCloseStatus === 0) {
      cleanAutoClose()
    } else if (state.autoCloseStatus === 1) {
      stopAllVideo()
    } else {
      createAutoClose()
    }
  }, [state.autoCloseStatus])

  const handleVideoList = useCallback((data: videoItem[]) => {
    const cloneVideoList = cloneDeep(state.videoList)

    data.forEach(a => {
      let isIndex = cloneVideoList.findIndex(b => {
        return b && a.sim === b.sim && a.passageway === b.passageway
      })
      if (isIndex !== -1) {
        // 该视频已停止
        if (cloneVideoList[isIndex] && !cloneVideoList[isIndex]?.url) {
          cloneVideoList[isIndex] = a
        }
      } else {
        if (endIndex.current >= state.passagewayNo) {
          endIndex.current = 0
        }
        cloneVideoList[endIndex.current] = a
        endIndex.current += 1
      }

    })

    handleSessionIds(cloneVideoList)
    _videoList.current = cloneVideoList
    setState({
      videoList: cloneVideoList
    })

    if (props.videoListChange) {
      props.videoListChange(cloneVideoList)
    }

    if (!state.time) {
      createBateTime()
    }

  }, [state.passagewayNo, state.videoList])

  /** 初始化video数量 */
  const initVideoList = () => {
    const newArr = new Array(16).fill(null)
    setState({
      videoList: newArr
    })
    cleanBateTime()
  }

  /**
   * 播放选择路数全部视频全部视频
   */
  const startAllVideo = useCallback(() => {
    if (props.playAllVideo) {
      props.playAllVideo(state.videoList)
    }
  }, [state.videoList])

  /**
   * 停止所有视频
   */
  const stopAllVideo = useCallback(() => {
    if (props.stopAllVideo) {
      props.stopAllVideo(state.videoList)
    }
    const cloneVideo = cloneDeep(state.videoList)

    setState({
      videoList: cloneVideo.map(item => {
        return item ? { ...item, url: '' } : item
      }),
      autoCloseStatus: 0
    })

    sessionIds.current = []
    cleanBateTime()
  }, [state.videoList])
  /**
   * 创建心跳
   */
  const createBateTime = () => {
    cleanBateTime()
    bateTimer.current = setInterval(bateTimeEvent, 10000)
  }

  const bateTimeEvent = () => {
    if (props.onBateTime) {
      props.onBateTime(sessionIds.current)
    }
  }

  /**
   * 清除心跳
   */
  const cleanBateTime = () => {
    if (bateTimer.current) {
      window.clearInterval(bateTimer.current)
    }
  }

  /**
   * 通道数改变
   * @param value
   */
  const passagewayChange = (value: number) => {
    setState({ passagewayNo: value })
    if (props.onPassagewayChange) {
      props.onPassagewayChange(value)
    }
  }

  /** 处理直播视频sessionIds,用于心跳 */
  const handleSessionIds = (videoList: Array<videoItem | null>) => {
    sessionIds.current = uniqBy(videoList.filter(item => item ? true : false), 'sessionId').map(item => item!.sessionId)
  }

  /**
   * 改变屏幕比
   */
  const changeScreen = (value: string) => {
    setState({ videoScreen: Number(value) })
  }

  /** 视频销毁点击 */
  const destroyClick = (url: string) => {
    const _: Array<videoItem | null> = cloneDeep(_videoList.current)
    const item = _.find(item => item && item.url === url)
    if (item) {
      const newArr = _.map(item => item && item.url === url ? { ...item, url: undefined } : item)
      // 判断是否有url
      const hasUrl = newArr.some(item => {
        if (item && item.url) {
          return true
        }
        return false
      })

      setState({
        videoList: newArr
      })
      _videoList.current = newArr

      /** 没有视频资源，清除心跳 */
      if (!hasUrl) {
        cleanBateTime()
        setState({ autoCloseStatus: 0 })
      }

      handleSessionIds(newArr)

      if (props.destroyClick) {
        props.destroyClick(item.passageway, item.sessionId, item.sim)
      }
    }
  }

  /** 视频播放按钮 */
  const onPlayClick = (obj: playObj) => {
    if (props.playClick) {
      props.playClick(obj)
    }
  }

  /** 创建自动关闭 */
  const createAutoClose = useCallback(() => {
    cleanAutoClose()
    autoCloseTimer.current = setTimeout(() => {
      setState({ autoCloseStatus: 1 })
    }, state.autoCloseTime * 60 * 1000)
  }, [state.autoCloseTime])

  /** 清除自动关闭 */
  const cleanAutoClose = () => {
    if (autoCloseTimer.current) {
      (window as any).clearTimeout(autoCloseTimer.current)
      autoCloseTimer.current = null
    }
  }
  /** 重置全部 */
  const resetAll = () => {
    stopAllVideo()
    bateTimer.current = null
    autoCloseTimer.current = null
    sessionIds.current = 0
    _videoList.current = []
    endIndex.current = 0
    setState({
      videoList: new Array(16).fill(null)
    })
  }

  return (
    <div className='broadcast broadcast-container' ref={ broadcastContainer }>
      {
        props.showHeaderTool !== false ?
          <div className='broadcast-tool'>
            <Tooltip title={ toolTitleIntls ? '1' + toolTitleIntls.channels : '' }>
              <AliIcon type='icononescreen1' className='broadcast-icon' onClick={ () => { passagewayChange(1) } } />
            </Tooltip>
            <Tooltip title={ toolTitleIntls ? '4' + toolTitleIntls.channels : '' }>
              <AliIcon type='icononescreen4' className='broadcast-icon' onClick={ () => { passagewayChange(4) } } />
            </Tooltip>
            <Tooltip title={ toolTitleIntls ? '6' + toolTitleIntls.channels : '' }>
              <AliIcon type='icononescreen6' className='broadcast-icon' onClick={ () => { passagewayChange(6) } } />
            </Tooltip>
            <Tooltip title={ toolTitleIntls ? '8' + toolTitleIntls.channels : '' }>
              <AliIcon type='icononescreen8' className='broadcast-icon' onClick={ () => { passagewayChange(8) } } />
            </Tooltip>
            <Tooltip title={ toolTitleIntls ? '9' + toolTitleIntls.channels : '' }>
              <AliIcon type='icononescreen9' className='broadcast-icon' onClick={ () => { passagewayChange(9) } } />
            </Tooltip>
            <Tooltip title={ toolTitleIntls ? '16' + toolTitleIntls.channels : '' }>
              <AliIcon type='icononescreen16' className='broadcast-icon' onClick={ () => { passagewayChange(16) } } />
            </Tooltip>
            <Dropdown
              overlay={ <ScreenMenu onClick={ changeScreen } /> }
              placement="bottomCenter"
              arrow
              getPopupContainer={ () => broadcastContainer.current }
            >
              <AliIcon type='iconquzhongdie' className='broadcast-icon' />
            </Dropdown>

            <Dropdown
              arrow
              overlay={
                (<SettingMenu
                  time={ state.autoCloseTime }
                  onClick={ (value: number) => {
                    setState({
                      autoCloseTime: value,
                      autoCloseStatus: value ? 2 : 0
                    })
                  } } />)
              }
              placement="bottomCenter"
              getPopupContainer={ () => broadcastContainer.current }
            >
              <AliIcon type='iconshezhi' className='broadcast-icon' />
            </Dropdown>
            <Tooltip title={ toolTitleIntls ? toolTitleIntls.allPlay : '' }>
              <AliIcon type='iconbofang1' className='broadcast-icon' onClick={ startAllVideo } />
            </Tooltip>
            <Tooltip title={ toolTitleIntls ? toolTitleIntls.allStop : '' }>
              <AliIcon type='icontingzhi' className='broadcast-icon' onClick={ stopAllVideo } />
            </Tooltip>
            <Tooltip title={ toolTitleIntls ? toolTitleIntls.resetAll : '' }>
              <AliIcon type='iconshuaxin-copy' className='broadcast-icon' onClick={ resetAll } />
            </Tooltip>
            {
              !isFullscreen ?
                <Tooltip title={ toolTitleIntls ? toolTitleIntls.fullscreen : '' }>
                  <AliIcon type='iconfull-screen' className='broadcast-icon' style={ { fontSize: 24 } } onClick={ toggle } />
                </Tooltip> :
                <Tooltip title={ toolTitleIntls ? toolTitleIntls.exitFullscreen : '' }>
                  <AliIcon type='iconun-full-screen' className='broadcast-icon' style={ { fontSize: 24 } } onClick={ toggle } />
                </Tooltip>
            }

          </div>
          : null
      }
      <div className='broadcast-content'>
        <div className={ classnames(
          'broadcast-videos',
          `video-passageway-${state.passagewayNo}`,
          props.className) }>
          {
            state.videoList && state.videoList.length ?
              state.videoList.map((item: videoItem, index: number) => {
                return <Video
                  key={ `broadcast_` + index }
                  hasVideo={ props.hasVideo }
                  hasAudio={ props.hasAudio }
                  type="broadcast"
                  passageway={ item ? item.passageway : '' }
                  vehicleNo={ item ? item.vehicleNo : '' }
                  url={ item ? item.url : '' }
                  sim={ item ? item.sim : '' }
                  videoScale={ state.videoScreen }
                  showTool={ props.showVideoTool !== false ? true : false }
                  destroyClick={ destroyClick }
                  isTimeoutTip={ props.isTimeoutTip }
                  mediaType={ props.mediaType }
                  onEnded={ () => {
                    if (props.onEnded) {
                      props.onEnded(item.passageway)
                    }
                  } }
                  onPlay={ () => {
                    onPlayClick({
                      passageway: item.passageway,
                      vehicleNo: item.vehicleNo,
                      simNo: item.sim
                    })
                  } }
                />
              })
              : null
          }
        </div>
      </div>
    </div>
  )
}

export default React.memo(Broadcast, areEqual)
