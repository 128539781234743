import React, { useEffect, useState } from 'react'
import InfoWrapper from "./infoWrapper"
import './index.less'
import { Echarts } from "components";
import { useInterval } from "react-use";
import { getVehicleGps } from "server/web/largeScreen";
import { getLocalJson } from "../../util/comm";
import { getCompany } from "../../server/web/companypra";

function BaiduMap() {

  const [points, setPoints] = useState<Array<[number, number, string]>>([])
  const [bmapOption, setBmapOption] = useState({
    roam: true,
    zoom: 6,
    center: [104.114129, 35.850339] as [number, number]
  })

  const queryData = async () => {
    getVehicleGps().then(res => {
      if (res && res.data.length) {
        setPoints(res.data.map(i => [parseFloat(i.longitude), parseFloat(i.latitude), i.id]))
      }
    })

    // let temp: Array<[number, number, string]> = []
    //
    //
    // for (let i = 0; i < 65535; i++) {
    //   temp.push([Math.random() * 60 + 74, Math.random() * 31 + 21, '440732427184816131'] as [number, number, string])
    // }
    // setPoints(temp)
  }

  useEffect(() => {
    const userInfo: any = getLocalJson('tx_userInfo')
    // 获取企业参数
    if (userInfo) {
      getCompany({ companyId: userInfo.orgId }).then(result => {
        if (result && result.data) {
          const { initLat = '35.850339', initLng = '104.114129', initZoomLevel = 5 } = result.data
          setBmapOption({
            roam: true,
            center: [parseFloat(initLng), parseFloat(initLat)],
            zoom: initZoomLevel
          })
        }
      })
    }

    queryData().then()
  }, [])

  useInterval(() => {
    queryData().then()
  }, 30000)

  return (
    <InfoWrapper>
      <div className='tx-large-map'>
        <div className='large-map-content'>
          <Echarts
            bmap={bmapOption}
            aibpoints={points}
          />
        </div>
      </div>
    </InfoWrapper>
  )
}

export default BaiduMap

