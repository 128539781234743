/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-12-03 09:52:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-14 18:13:52
 */
import { observable, action } from 'mobx'
import { userAlarmSetting } from 'server/web/userAlarmSetting'


class UserSettingStore {
  @observable baseSetting: userAlarmSetting = {
    streamType: '0',
    videoDataType: '0'
  }

  /** 更新值
   *@param isUpdate 是否为更新, true则不更新positionStory中地图相关信息
   */
  @action
  updateSetting = (data: userAlarmSetting) => {

    this.baseSetting = data
  }
}

const userSettingStore: UserSettingStore = new UserSettingStore()

export { UserSettingStore }
export default userSettingStore
