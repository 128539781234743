import React from 'react'
import axios from 'server/axios'
import {Info} from "components"
import {useAxios} from "util/hook"
import {InfoItems} from "components/info";

interface DetailProps {
  infoItem: InfoItems[]
  detailUrl: string
  record: any
}

const CommonDetail: React.FC<DetailProps> = ({record, detailUrl, infoItem}) => {
  const [{data, loading}] = useAxios({
    axios: (param) => axios.post(detailUrl, param),
    payload: {id: record.id},
  })

  return <Info loading={loading} items={infoItem} data={data} row={3} titleWidth={125}/>
}

export default CommonDetail;