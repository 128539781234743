/*
 * @Description: 坐标系转换
 * @Author: 谢永红
 * @Date: 2020-07-02 14:13:03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-30 20:48:00
 */
import { isNaN, isNumber } from 'lodash-es'
import { MapType } from './comm'

const x_PI: number = 3.14159265358979324 * 3000.0 / 180.0;
const PI: number = 3.1415926535897932384626;
const a: number = 6378245.0;
const ee: number = 0.00669342162296594323;

/**
 * 计算 lat
 * @param lng 经度
 * @param lat 纬度
 */
const transformlat = (lng: number, lat: number): number => {
	let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
	ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
	ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0;
	ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0;
	// 保留6位小数
	return ret
}

/**
 * 计算 lng
 * @param lng 经度
 * @param lat 纬度
 */
const transformlng = (lng: number, lat: number): number => {
	let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
	ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
	ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0;
	ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0;
	// 保留6位小数
	return ret
}

/**
 * 判断是否在国内，不在国内则不做偏移
 * @param lng 经度
 * @param lat 纬度
 */
const isChina = (lng: number, lat: number): boolean => {
	return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false);
}

/**
 * GPS 转 高德、谷歌
 * @param lng 经度
 * @param lat 纬度
 */
export const wgs84togcj02 = (slng: number, slat: number): [number, number] => {
	const { lng, lat } = changeNumber(slng, slat)

	if (isChina(lng, lat)) {
		return [lng, lat]
	}

	let dlat: number = transformlat(lng - 105.0, lat - 35.0);
	let dlng: number = transformlng(lng - 105.0, lat - 35.0);
	const radlat: number = lat / 180.0 * PI;
	let magic: number = Math.sin(radlat);

	magic = 1 - ee * magic * magic;
	const sqrtmagic = Math.sqrt(magic);

	dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
	dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
	const mglat = lat + dlat;
	const mglng = lng + dlng;

	return [Number(mglng.toFixed(6)), Number(mglat.toFixed(6))]
}

/**
 * 高德、谷歌 转 GPS
 * @param lng 经度
 * @param lat 纬度
 */
export const gcj02towgs84 = (slng: number, slat: number): [number, number] => {

	const { lng, lat } = changeNumber(slng, slat)
	if (isChina(lng, lat)) {
		return [lng, lat]
	}

	let dlat: number = transformlat(lng - 105.0, lat - 35.0);
	let dlng: number = transformlng(lng - 105.0, lat - 35.0);
	const radlat: number = lat / 180.0 * PI;

	let magic: number = Math.sin(radlat);
	magic = 1 - ee * magic * magic;

	const sqrtmagic: number = Math.sqrt(magic);

	dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
	dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);

	const mglat: number = lat + dlat;
	const mglng: number = lng + dlng;

	const resLat = lat * 2 - mglat
	const resLng = lng * 2 - mglng

	return [Number(resLng.toFixed(6)), Number(resLat.toFixed(6))]
}

/**
 * 谷歌、高德 转 百度
 * @param lng 经度
 * @param lat 纬度
 */
const gcj02tobd09 = (lng: number, lat: number): [number, number] => {
	const z: number = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI);
	const theta: number = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI);
	const bd_lng: number = z * Math.cos(theta) + 0.0065;
	const bd_lat: number = z * Math.sin(theta) + 0.006;

	return [Number(bd_lng.toFixed(6)), Number(bd_lat.toFixed(6))]
}

/**
 * 百度 转 谷歌、高德 
 * @param lng 经度
 * @param lat 纬度
 */
const bd09togcj02 = (lng: number, lat: number): [number, number] => {
	const x_pi: number = 3.14159265358979324 * 3000.0 / 180.0;
	const x: number = lng - 0.0065;
	const y: number = lat - 0.006;
	const z: number = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
	const theta: number = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
	const gg_lng: number = z * Math.cos(theta);
	const gg_lat: number = z * Math.sin(theta);

	return [Number(gg_lng.toFixed(6)), Number(gg_lat.toFixed(6))]
}

/**
 * gps 转 百度
 * @param lng 经度
 * @param lat 纬度 
 */
export const wgs84tobd09 = (slng: number, slat: number): [number, number] => {
	const { lng, lat } = changeNumber(slng, slat)
	const point: [number, number] = wgs84togcj02(lng, lat)
	return gcj02tobd09(point[0], point[1])
}

/**
 * 百度 转 GPS
 * @param lng 经度
 * @param lat 纬度 
 */
export const bd09towgs84 = (slng: number, slat: number): [number, number] => {
	const { lng, lat } = changeNumber(slng, slat)
	const point: [number, number] = bd09togcj02(lng, lat)
	return gcj02towgs84(point[0], point[1])
}

const changeNumber = (lng: any, lat: any): { lng: number, lat: number } => {
	return {
		lng: isNaN(lng) ? 0 : isNumber(lng) ? lng : Number(lng),
		lat: isNaN(lat) ? 0 : isNumber(lat) ? lat : Number(lat)
	}
}

export const towgs84 = (lnglat: [number, number], mapType: string = MapType.amap): [number, number] => {
	if (mapType === MapType.amap) {
		return gcj02towgs84(lnglat[0], lnglat[1])
	}
	return bd09towgs84(lnglat[0], lnglat[1])
}